html,
body {
	background: #151d3b;
	color: #151d3b;
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow: hidden;
}

#app {
	font-family: sans-serif;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}
#cobe {
	background-color: #151d3b;
	width: 1000px;
	height: 1000px;
	overflow: hidden;
}

#overlay {
	width: 100vw;
	height: 100vh;
	background-color: #151d3b;
	mix-blend-mode: lighten;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
	overflow: hidden;
}

#logo {
	background-image: url(logo.svg);
	background-size: contain;
	background-repeat: no-repeat;
	width: 300px;
	height: 100px;
	position: absolute;
	left: 10vw;
	bottom: 10vw;
	z-index: 20;
}

#maillink {
	direction: rtl;
	unicode-bidi: bidi-override;
	text-decoration: none;
	font-family: Arial, Helvetica, sans-serif;
	color: #8e8e8e;
	position: absolute;
	bottom: 50px;
	left: 10vw;
	cursor: pointer;
	z-index: 30;
	font-size: 12px;
}
#maillink:hover {
	text-decoration: underline;
}

@media only screen and (max-width: 768px) {
	body,
	html {
		height: 80vh;
	}
	#app {
		height: 80vh;
	}
	#overlay {
		height: 80vh;
	}
	#maillink {
		font-size: 10px;
		left: 10vw;
		bottom: 10vh;
	}

	#logo {
		width: 200px;
		height: 100px;
		bottom: 30vw;
	}

	#cobe {
		width: 350px;
		height: 350px;
	}
}
